import * as React from "react";
import { HtmlHead, Link } from "@cdv/jazz-web/components";
import { Typography, Box, Container } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";

export const Head = () => (
  <HtmlHead title="Websites | Centrum dopravního výzkumu, v. v. i.">
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="description" content="Websites" lang="cs" />
    <meta name="keywords" content="websites" lang="cs" />
    <meta name="robots" content="index, follow" />
    <meta name="rating" content="general" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  </HtmlHead>
);

export default function Page() {
  const aCss = {
    a: {
      textDecoration: "none",
      color: "#e05206",
      borderBottom: "1px solid transparent",
      transitionDuration: "0.3s",
    },
    "a:hover": {
      borderBottom: "1px solid #e05206",
      transitionDuration: "0.3s",
    },
  };

  return (
    <Layout>
      <Container sx={aCss}>
        <ul>
          <li>
            <Link to="http://www.srazenazver.cz/en">www.srazenazver.cz</Link>
            <br />
            <em>
              AVC report application for the Czech road and railway networks
            </em>
          </li>
          <li>
            <Link to="http://rupok.cz/index.php?lang=en">www.rupok.cz</Link>
            <br />
            <em>Road and railway network risks due to natural disasters</em>
          </li>
          <li>
            <Link to="http://www.kdeplus.cz/en">www.kdeplus.cz</Link>
            <br />
            <em>A website devoting to our KDE+ method and KDE+ software</em>
          </li>
          <li>
            <Link to="http://kdeplus.cdvgis.cz/">kdeplus.cdvgis.cz</Link>
            <br />
            <em>Application of KDE+ method worldwide.</em>
          </li>
          <li>
            <Link to="http://www.kdebourame.cz/en">www.kdebourame.cz</Link>
            <br />
            <em>
              Clusters of selected causes of traffic crashes within the Czech
              road network
            </em>
          </li>
          <li>
            <Link to="http://www.cyklokomfort.cz/en">www.cyklokomfort.cz</Link>
            <br />
            <em>
              How comfortable are your cycling tracks? Use our system in order
              to measure vibrations along cycling tracks…
            </em>
          </li>
          <li>
            <Link to="http://avison.cdvinfo.cz">www.avison.cdvinfo.cz</Link>
            <br />
            <em>
              Traffic crashes on Czech roads in low visibility – a web map
              application
            </em>
          </li>
          <li>
            <Link to="http://roca.cdvgis.cz/czechia/">roca.cdvgis.cz</Link>
            <br />
            <em>
              Road alignment identification and horizontal curve radii
              computation - a web map application
            </em>
          </li>
          <li>
            <Link to="http://nehody.cdvgis.cz/">nehody.cdvgis.cz/</Link>
            <br />
            <em>Traffic crashes on Czech roads – statistics, graphs, map</em>
          </li>
          <li>
            <Link to="http://nehody.cdvgis.cz/cyklo/olomouc">
              nehody.cdvgis.cz/cyklo/olomouc
            </Link>
            <br />
            <em>Accident of cyclists in town of Olomouc</em>
          </li>
          <li>
            <Link to="http://blackspots.cdvgis.cz/pihel/">
              blackspots.cdvgis.cz/pihe
            </Link>
            l<br />
            <em>AVC monitoring in the hunting area Pihel</em>
          </li>
          <li>
            <Link to="http://blackspots.cdvgis.cz/niva/">
              blackspots.cdvgis.cz/niva
            </Link>
            <br />
            <em>Wild boar migration near the hunting area Niva</em>
          </li>
        </ul>
      </Container>
    </Layout>
  );
}
